<template>
  <v-card
    flat
    tile
    class="mx-auto"
    width=100%
    height=100%
  >
     <v-toolbar
      color="indigo"
      dark
    >
      <v-btn @click="onback"  icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">{{name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
 
    <v-card class="mx-auto">                                 
                    <v-card-text class="pt-4">
                        <v-form ref="form">
                           
                            <v-text-field
                            label="device number"
                            v-model="serno"
                            required
                            ></v-text-field>
                           <v-text-field
                            label="device name"
                            v-model="sernoname"
                            required
                            ></v-text-field>
                         <v-combobox
                          v-model="sernotype"
                          label="device type"
                          :items="sertype"
                          required
                        />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="submit" class="green white--text">Add</v-btn>
                       
                    </v-card-actions>

                </v-card>
       <v-dialog max-width="400" v-model="showdialog" persistent>
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{dialogtitle}}
          </v-card-title>
  
          <v-spacer></v-spacer>
          <v-card-text>
 
          </v-card-text>
          <v-card-text>
            {{dialogmessage}}
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="hidedialog"
            >
              {{dialogbttile}}
            </v-btn>
          </v-card-actions>
        </v-card>
     </v-dialog>

    <v-spacer></v-spacer>
  </v-card>
</template>

<script>

import { Base64 } from 'js-base64'
  export default {
   

    data: () => ({
        xsrf:'',
        name: 'Add Device', 
        serno:'',
        sernotype:'',
        sernoname:'',
        username: '',
        password: '',
        dialogtitle:'',
        dialogmessage:'',
        dialogbttile:'',
        showdialog:false,
        sertype: [
          "CAMERA",
          "DOORBELL"
        ],
    }),
    created() {
       
            var xsrf = this.getCookie("_xsrf")
             if(xsrf!=""){
                var xsrflist  = xsrf.split("|");
              
               // console.log("xsrflist ->",xsrflist[0])
                this.xsrf = Base64.decode(xsrflist[0])
                //console.log("xsrf ->",this.xsrf)
             }

      
    },
    mounted(){
        console.log("AddDevice mounted   ");
      var UserInfo = localStorage.getItem('userinfo')
      if(UserInfo!=null && UserInfo!= undefined){
        var obj = JSON.parse(UserInfo);
        this.username = obj.user;
        this.password = obj.pwd;
      }
    },
    destroyed() {
        console.log("AddDevice destroyed   ");
    },
    methods: {
      getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1){
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
        hidedialog(){
                this.showdialog = false;
        },
        submit () {
                    
                if(this.serno =='' ||this.sernotype ==''|| this.sernoname ==''){
                    this.dialogtitle="Warning";
                    this.dialogmessage="The device number you want to add is empty  !";
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
                }
                var sessionon = localStorage.getItem('sessionon')
                var  UserInfo = {user:this.username,pwd:this.password,serno:this.serno,name:this.sernoname,type:this.sernotype};
               
                this.axios.post('/api/addequipment',UserInfo,{
                 headers: {
                  'Access-Control-Allow-Origin': "*",
                  'Authorization': sessionon,
                  'X-Xsrftoken':this.xsrf
                 }
                })
                .then(res=>{
                    
                    
                    if(res.data.state==200){

                      
                      this.global.sendToServer({
                        "eventName": "__subscribe",
                        "data": {
                          "sessionId": this.global.getHomeId(),
                          "sessionType": "IE",
                          "messageId": this.global.newGuid(),
                          "from": this.global.getMeId(),
                          "to": this.serno
                          }
                          });
                          
                       this.global.setInitData(false)
                       this.$router.push("/")    
                    }else if(res.data.state==300){
                          this.$router.push('Login')                                           
                    }else{
                     console.log('res=>',res.data); 
                    this.dialogtitle="Warning";
                    this.dialogmessage=res.data.message+"  error  code "+res.data.state;
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
               
                    }
                    
                            
                })
                .catch(error=>{
                    console.log('error=>',error);
                     this.dialogtitle="Warning";
                    this.dialogmessage="net work  error "+error;
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
                })

               
        },
       onback: function(){
            
           this.$router.push("/")
      }
  
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-card {
    text-align: center;

}

.v-card__title {
    justify-content: center;
}

.v-btn {
    width: 100%;
}

.link-color {
    color: #d38841;
    text-decoration: none;
}



.v-card__actions {
    display: block;
}
</style>